/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function openShare() {
    $(".open-share").on("click", function(e){
      e.preventDefault();
      $(this).parents('.share-wrap').find(".share-box").fadeToggle(300);
    });
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        openShare();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        var topSlider = new Swiper ('.front-slider .swiper-container', {
          direction: 'horizontal',
          loop: true,
          slidesPerView: 3,
          spaceBetween: 0,
          spaceBetween: 15,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
          },
          slideToClickedSlide: false,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            414: {
              slidesPerView: 2,
              spaceBetween: 0,
              centeredSlides: false,
            },
          }
        })

        var shopSlider = new Swiper ('.shop-home .swiper-container', {
          direction: 'horizontal',
          freeMode: true,
          slidesPerView: 4,
          spaceBetween: 20,
          scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
          },
          breakpoints: {
            // when window width is >= 320px
            414: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 4
            }
          }
        })

        var trendingThumbSlider = new Swiper ('.trending .swiper-container.thumb-slider', {
          direction: 'vertical',
          loop: false,
          slidesPerView: 6,
          shortSwipes: false,
          // autoHeight: true,
          // slideToClickedSlide: false,
          breakpoints: {
            // when window width is >= 320px
            // 414: {
            //   slidesPerView: 2
            // },
            // 768: {
            //   slidesPerView: 3
            // }
          }
        })

        var trendingMainSlider = new Swiper ('.trending .swiper-container.main-slider', {
          thumbs: {
            swiper: trendingThumbSlider,
          }
        })

        trendingMainSlider.on('slideChange', function () {
            $('.swiper-container.thumb-slider .swiper-slide').removeClass('swiper-slide-active');
            $($('.swiper-container.thumb-slider .swiper-slide')[trendingMainSlider.activeIndex]).addClass('swiper-slide-active');
        });

        var velvetSlider = new Swiper ('.velvet .swiper-container', {
          slidesPerView: 2,
          loop: true,
          slidesPerGroup: 2,
          direction: 'horizontal',
          spaceBetween: 20,
          navigation: {
            nextEl: '.swiper-button-next.velvet-button',
            prevEl: '.swiper-button-prev.velvet-button',
          },
          breakpoints: {
            414: {
              spaceBetween: 10
            }
          }
        })
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // FAQ
        $('.question').click(function(e){
          e.preventDefault();
          $(this).toggleClass('open');
          $(this).siblings('.answer').slideToggle();
        });

        $('.faq:first-child .question').trigger('click');
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
